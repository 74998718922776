import React from 'react';
import { graphql } from 'gatsby';
import { t } from '../helpers/language';
import { Intro, Section, Swirl, Prefooter } from '@partials';
import { FaChevronRight } from 'react-icons/fa';
import { getColor } from '../helpers/colors';
import { PageTransition } from '../helpers/pageTransitions';

const Error404 = ({ data, pageContext }) => {
  return (
    <PageTransition>
      <Intro
        title={'Page Not Found'}
        breadcrumbs={pageContext.breadcrumbs}
        action={{
          iconAfter: <FaChevronRight />,
          children: t('spa_chooser_cta'),
          to: '/spa-chooser'
        }}
      >
        <Swirl toColor={getColor('light-gray')} />
      </Intro>
      <Section
        padding={'double'}
        background={'light-gray'}
        heading={`Page Not Found`}
        lead={`Sorry, it seems you were trying to access a page that doesn't exist. Please check the spelling of the URL you were trying to access and try again.`}
      ></Section>
      <Section animate={true} background={'light-gray'} padding={'top'}>
        <Prefooter />
      </Section>
    </PageTransition>
  );
};

export const pageQuery = graphql`
  query PageNotFoundQuery($language: String!) {
    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
    }
  }
`;

export default Error404;
